<!-- 薪资发放 -->
<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="单位名称">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.companyId">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="设备类型">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.attendanceType">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">门禁设备</a-select-option>
                        <a-select-option value="2">考勤设备</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" class="item" @click="showSettingModal(null)">
                    添加考勤设置
                </a-button>
            </a-row>
        </section>
        <section class="list-single-container" v-if="listMap.length">
            <a-row class="list-border list-item m-t-18" v-for="(item,ind) in listMap" :key="ind">
                <a-row class="action-btn-group">
                    <a-row>
                        <a-button size="small" class="m-b-8" @click="showSettingModal(item)">编辑</a-button>
                        
                        <a-popconfirm title="确定删除该考勤设置?" @confirm="deleteItem(item.id)"><a-button size="small">删除</a-button></a-popconfirm>
                    </a-row>
                </a-row>
                <p class="m-b-18" style="color:rgba(255,255,255,1);font-size:16px;margin-bottom:24px">{{item.companyName}}</p>
                <a-row class="m-b-10">
                    <a-col :span="4" style="color:#C9DFFF">考勤设备：{{item.attendanceType == 1 ? '门禁设备' : '考勤设备'}}</a-col>
                    <a-col :span="18">
                        <a-row>
                            <a-col class="m-b-16" :span="12" style="color:#a8e0a8">上午-上班时间：{{item.startWorkTimeA}}</a-col>
                            <a-col class="m-b-16" :span="12" style="color:#a7a7ec">上午-下班时间：{{item.endWorkTimeA}}</a-col>
                            <a-col class="m-b-16" :span="12" style="color:#a8e0a8">下午-上班时间：{{item.startWorkTimeB}}</a-col>
                            <a-col class="m-b-16" :span="12" style="color:#a7a7ec">下午-下班时间：{{item.endWorkTimeB}}</a-col>
                        </a-row>
                    </a-col>
                    <!-- <a-col :span="4">上午-上班时间：{{item.startWorkTimeA}}</a-col>
                    <a-col :span="4">上午-下班时间：{{item.endWorkTimeA}}</a-col>
                    <a-col :span="4">下午-下班时间：{{item.startWorkTimeB}}</a-col>
                    <a-col :span="4">下午-下班时间：{{item.endWorkTimeB}}</a-col> -->
                </a-row>
               
            </a-row>
        </section>
        <empty v-else :height="430" />
         <section class="pagination-container">
            <a-pagination size="default" :current="pageNum" :total="total" hide-on-single-page @change="changePageNum" />
        </section>

        <!-- modal -->
        <a-modal class="modal-container" centered v-model="settingModal.visible" width="600px" :title="settingModal.title" :footer="false" :destroyOnClose="true">
            <a-form :form="form">
               <a-form-item :label-col="{span:6}" :wrapper-col="{span:16}" label="所属单位">
                   <a-select placeholder="请选择" v-decorator="['companyId',{rules:[{required:true,message:'请选择所属单位'}]}]">
                       <a-select-option v-for="(item,key) in companyMap" :key="key" :value="item.id">{{item.companyName}}</a-select-option>
                   </a-select>
               </a-form-item>
               <a-form-item :label-col="{span:6}" :wrapper-col="{span:16}" label="考勤设备">
                   <a-select placeholder="请选择" v-decorator="['attendanceType',{rules:[{required:true,message:'请选择所属单位'}]}]">
                       <a-select-option :value="1">门禁设备</a-select-option>
                       <a-select-option :value="2">考勤设备</a-select-option>
                   </a-select>
               </a-form-item>
               <a-form-item :label-col="{span:6}" :wrapper-col="{span:16}" label="上午-上班时间">
                   <a-time-picker v-decorator="['startWorkTimeA',{rules:[{required:true,message:'请选择上午上班时间'}]}]" format="HH:mm"></a-time-picker>
               </a-form-item>
               <a-form-item :label-col="{span:6}" :wrapper-col="{span:16}" label="上午-下班时间">
                   <a-time-picker v-decorator="['endWorkTimeA',{rules:[{required:true,message:'请选择上午下班时间'}]}]" format="HH:mm"></a-time-picker>
               </a-form-item>
               <a-form-item :label-col="{span:6}" :wrapper-col="{span:16}" label="下午-上班时间">
                   <a-time-picker v-decorator="['startWorkTimeB',{rules:[{required:true,message:'请选择下午上班时间'}]}]" format="HH:mm"></a-time-picker>
               </a-form-item>
               <a-form-item :label-col="{span:6}" :wrapper-col="{span:16}" label="下午-下班时间">
                   <a-time-picker v-decorator="['endWorkTimeB',{rules:[{required:true,message:'请选择下午下班时间'}]}]" format="HH:mm"></a-time-picker>
               </a-form-item>
               <a-row class="form-btn-group">
                   <a-button class="btn-save" @click="submit"><a-icon type="save"  />保存</a-button>
                   <a-button class="btn-cancel"  @click="settingModal.visible = false">取消</a-button>
               </a-row>
               
           </a-form>
        </a-modal>
    </section>
</template>

<script>
import moment from 'moment'
import empty from '@/components/empty'
export default {
    data(){
        return{
            searchInfo:{},
            companyMap:[],
            pageNum:1,
            total:0,
            listMap:[],
            settingModal:{
                title:'添加考勤设置',
                visible:false
            },
            attendanceId:''
        }
    },
    components:{empty},
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
       this.init() 
    },
    mounted(){
        this.queryAttendanceSettingList()
    },
    methods:{
        init(){
            this.queryCompanyDropDownBox()
        },
        //单位下拉
        queryCompanyDropDownBox(){
            this.$api.queryCompanyDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyMap = res.data || []
                }
            })
        },
        //查询考勤设置列表
        queryAttendanceSettingList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            this.$api.queryAttendanceSettingList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        //重置搜索
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryAttendanceSettingList()
        },
        search(){
            this.pageNum = 1
            this.queryAttendanceSettingList()
        },
        showSettingModal(list){
            this.settingModal.visible = true
            this.settingModal.title = list ? '编辑考勤设置' : '新增考勤设置';
            this.attendanceId = list ? list.id : ''
            this.attendanceId && this.backfillForm(list)
        },
        backfillForm(list){
            let formData = {
                startWorkTimeA:list.startWorkTimeA ? moment(list.startWorkTimeA,'HH:mm') : null,
                endWorkTimeA:list.endWorkTimeA ? moment(list.endWorkTimeA,'HH:mm') : null,
                startWorkTimeB:list.startWorkTimeB ? moment(list.startWorkTimeB,'HH:mm') : null,
                endWorkTimeB:list.endWorkTimeB ? moment(list.endWorkTimeB,'HH:mm') : null,
                companyId:list.companyId,
                attendanceType:parseInt(list.attendanceType)
            }
            this.$nextTick(() =>{
                this.form.setFieldsValue(formData)
            })
        },
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    formData.startWorkTimeA = formData.startWorkTimeA.format('HH:mm')
                    formData.endWorkTimeA = formData.endWorkTimeA.format('HH:mm')
                    formData.startWorkTimeB = formData.startWorkTimeB.format('HH:mm')
                    formData.endWorkTimeB = formData.endWorkTimeB.format('HH:mm')
                    if(this.attendanceId){
                        formData.id = this.attendanceId
                    }
                    
                    let apiName = this.attendanceId ? 'updateAttendanceSetting' : 'insertAttendanceSetting'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.settingModal.visible = false;
                            this.queryAttendanceSettingList()
                        }
                    })
                }
            })
        },
        //删除设置项
        deleteItem(id){
            this.$api.deleteAttendanceSetting({id}).then(res =>{
                if(res.code === 200){
                    this.queryAttendanceSettingList()
                }
            })
        },
        //分页
        changePageNum(p){
            this.pageNum = p
            this.queryAttendanceSettingList()
        }
    }
}
</script>