<template>
    <section>
        <section class="nav-container m-t-16">
            <a @click="navActiveKey = item.key" :class="item.key == navActiveKey ? 'active' : ''" v-for="item in navMap" :key="item.key">{{item.name}}</a>
        </section>
        <section>
            <!-- <person-config v-if="navActiveKey === 1" /> -->
            <attendance-config v-if="navActiveKey === 1" />
        </section>
    </section>
</template>

<script>
// import personConfig from './components/person'
import attendanceConfig from './components/attendance'
export default {
    data(){
        return{
            navActiveKey:1,
            navMap:[
                // {name:'人员信息设置',key:1},
                {name:'考勤设置',key:1},
            ]
        }
    },
    components:{attendanceConfig}
}
</script>